import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/nprogress/nprogress.css");
import(/* webpackMode: "eager" */ "/app/node_modules/slick-carousel/slick/slick.css");
import(/* webpackMode: "eager" */ "/app/node_modules/slick-carousel/slick/slick-theme.css");
import(/* webpackMode: "eager" */ "/app/node_modules/bootstrap/dist/css/bootstrap.css");
import(/* webpackMode: "eager" */ "/app/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
import(/* webpackMode: "eager" */ "/app/src/components/Provider/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css")